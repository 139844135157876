/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { Fetish } from '@/store/fetish/types';

const namespace: string = 'fetish';

export default class FetishModelChoicePage extends Vue {
	@State('fetish') fetish: Fetish | undefined;
	@Mutation('setFetishCategory', {namespace}) setFetishCategory: any;
	@Mutation('setNameModel', {namespace}) setNameModel: any;
	@Getter('getFetishCategory', {namespace}) getFetishCategory: any;
	@Getter('getFetishModels', {namespace}) getFetishModels: any;
	@Getter('getSelectedCategory', {namespace}) getSelectedCategory: any;
	@Action('fetchFetishModels', {namespace}) fetchFetishModels: any;

	modelChoice(name: string) {
		this.setNameModel(name);
		this.$router.push({ name: 'fetish-video-choice' }).then(r => r);
	}

	mounted() {
		this.fetchFetishModels();
	}
}
